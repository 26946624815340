



























import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  useContext,
  useFetch,
  useMeta
} from '@nuxtjs/composition-api';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import NostoCategory from 'atoms/NostoCategory.vue';

import {
  useCanonical,
  useRequestPath,
  useStructuredData
} from '~/diptyqueTheme/composable';
import { useHreflangs } from '~/diptyqueTheme/composable/useHreflangs';
import { getCategoryPath } from '~/diptyqueTheme/helpers/nosto';
import { Logger } from '~/helpers/logger';
import { CategoryTypeEnum } from '~/modules/catalog/product/enums/CategoryTypeEnum';
import type { CategoryTree } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'CategoryPage',
  components: {
    CategoryDefault: () => import('templates/category/CategoryDefault.vue'),
    CategoryCollection: () =>
      import('templates/category/CategoryCollection.vue'),
    CategoryMain: () => import('templates/category/CategoryMain.vue'),
    NostoCategory
  },
  setup() {
    const { getFullCategoryBasedOnRequestPath, getBreadcrumbs } =
      useRequestPath();
    const {
      app: { i18n },
      error: nuxtError
    } = useContext();
    const { addTags } = useCache();
    const activeCategory = ref<CategoryTree | null>(null);
    const loading = ref<boolean | null>(true);
    const noCategoryData = ref<boolean | null>(false);
    const { getCategoryBreadcrumbsStructuredData } = useStructuredData();
    const nostoCategoryId = computed(() => getCategoryPath(breadcrumbs.value));

    const renderer = computed(() => {
      return CategoryTypeEnum.CATEGORY_TREE;
    });

    const breadcrumbs = computed(() =>
      getBreadcrumbs(activeCategory.value, true)
    );

    const fetchCategoryData = async () => {
      try {
        const result = await getFullCategoryBasedOnRequestPath();
        if (result) {
          activeCategory.value = result;
        } else {
          noCategoryData.value = true;
          setInitialLoadFromServer(false);
          return nuxtError({ statusCode: 404 });
        }
      } catch (error) {
        noCategoryData.value = true;
        Logger.error(
          'Get Full Product Category from URL path by route [ERROR]',
          error
        );
        console.error(error);
        return nuxtError({ statusCode: 404 });
      }
    };

    const initialLoadFromServer = ref(true);
    const { canonicalCategoryLink } = useCanonical();

    const setInitialLoadFromServer = (state: boolean) => {
      initialLoadFromServer.value = state;
    };

    useFetch(async () => {
      loading.value = true;
      setInitialLoadFromServer(process.server);
      await fetchCategoryData();
      loading.value = false;

      const tags = [{ prefix: CacheTagPrefix.View, value: 'category' }];
      addTags(tags);
    });

    const listingLayout = computed(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return activeCategory.value?.listing_layout;
    });

    const metaDescription = computed(() => {
      const description = [];

      switch (listingLayout.value) {
        case 'main_category':
          description.push(i18n.t('Discover the universe'));
          break;
        case 'collection':
          description.push(i18n.t('Discover the collection'));
          break;
        default:
          description.push(i18n.t('Discover the products'));
      }

      description.push(activeCategory.value?.name ?? '');
      description.push(
        i18n.t(
          'Diptyque, a precursor of an art of living through the senses, where perfume and art permeate everything.'
        )
      );

      return description.join(' ');
    });

    const metaTags = computed(() =>
      activeCategory.value
        ? {
            title:
              activeCategory.value?.meta_title ||
              `${activeCategory.value?.name} | Diptyque Paris`,
            meta: [
              {
                hid: 'og:title',
                property: 'og:title',
                content:
                  activeCategory.value?.meta_title ||
                  `${activeCategory.value?.name} | Diptyque Paris`
              },
              {
                hid: 'description',
                name: 'description',
                content:
                  activeCategory.value?.meta_description ||
                  metaDescription.value
              },
              {
                hid: 'og:description',
                name: 'og:description',
                content:
                  activeCategory.value?.meta_description ||
                  metaDescription.value
              }
            ],
            link: [canonicalCategoryLink(activeCategory?.value)],
            script: [
              {
                type: 'application/ld+json',
                json: getCategoryBreadcrumbsStructuredData(breadcrumbs.value)
              }
            ]
          }
        : {}
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useMeta(() => metaTags.value);

    onMounted(() => {
      document.body.classList.add('has-sticky');
      fetchCategoryData();
    });

    onUnmounted(() => {
      document.body.classList.remove('has-sticky');
    });

    return {
      loading,
      noCategoryData,
      activeCategory,
      nostoCategoryId,
      renderer,
      listingLayout,
      metaTags,
      breadcrumbs
    };
  },

  async asyncData(ctx) {
    const { getHrefLangs } = useHreflangs(ctx);
    const hrefLangsLinks = await getHrefLangs();
    return {
      hrefLangsLinks
    };
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  head() {
    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      link: this.hrefLangsLinks
    };
  }
});
