import type { BreadcrumbType as Breadcrumb } from '~/diptyqueTheme/composable/useRequestPath/types';

/**
 * Generates a Nosto category path from a list of breadcrumbs.
 * e.g., /home/diffusers/car diffuser
 *       /home/diffusers/electric diffusers
 * @param breadcrumbs - An optional array of breadcrumb objects.
 * @returns The formatted category path string.
 */
export default function getCategoryPath(breadcrumbs?: Breadcrumb[]): string {
  if (!breadcrumbs?.length) {
    return '';
  }

  return (
    '/' +
    breadcrumbs
      .slice(1) // Skips the first breadcrumb
      .filter((crumb) => crumb.text?.trim()) // Excludes empty or whitespace-only text
      .map((crumb) => crumb.text.toLowerCase())
      .join('/')
  );
}
