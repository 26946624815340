import { useNostoStore } from '~/diptyqueTheme/stores/nosto';
import { Logger } from '~/helpers/logger';

interface NostoResponse {
  recommendations: {
    [slotId: string]: {
      result_id: string;
      products: Array<{
        product_id: string;
        [key: string]: unknown;
      }>;
      div_id: string;
      result_type: string;
      element: string;
      title: string;
      source_product_ids: Array<unknown>;
      params: {
        nosto: string;
      };
    };
  };
}

function checkIsNostoLoaded() {
  let temp = 0;
  window.nostojs(() => {
    temp = 1;
  });

  return temp === 1;
}

export default function loadRecommendations(slot: string): Promise<string[]> {
  const { getCategoryId, getProductId } = useNostoStore();

  return new Promise((resolve, reject) => {
    if (!checkIsNostoLoaded()) {
      reject('Nosto is not initialised');
    }

    window.nostojs?.((api) => {
      const session = api.defaultSession();
      let action;
      let id;
      let recommendations: string[] = [];

      if (slot.includes('front')) {
        action = session.viewFrontPage();
      } else if (slot.includes('product') && getProductId()) {
        id = getProductId();
        action = session.viewProduct(id);
      } else {
        action = session.viewOther();
      }

      action
        .setPlacements([slot])
        .load()
        .then((response: NostoResponse) => {
          const slotRecommendations = response?.recommendations?.[slot];

          if (!slotRecommendations) {
            Logger.info('[NOSTO]: No recommendations received for slot', slot);
            resolve(recommendations);
            return;
          }

          if (!slotRecommendations?.products.length) {
            Logger.info('[NOSTO]: No products received for slot', slot);
            resolve(recommendations);
            return;
          }

          recommendations = response.recommendations[slot].products.map((product) => product.product_id);
          resolve(recommendations);
        })
        .catch(reject);
    });
  });
}
