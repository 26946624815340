import { useContext } from '@nuxtjs/composition-api';

const ARIA_VALIDATION_MESSAGES = {
  "required": "{errorVariable} is required",
  // "email_ex": "Looks like field {errorVariable} is not valid, please check"
}

export const getAriaLabel = (failedRules: Record<PropertyKey, string>, errorVariable?: string): string => {
  const {
    app: { i18n }
  } = useContext();

  const ruleName = Object.keys(failedRules)[0] || '';

  if (errorVariable) {
    return i18n.t(ARIA_VALIDATION_MESSAGES[ruleName], { errorVariable: i18n.t(errorVariable) }) as string || '';
  }

  return i18n.t(ARIA_VALIDATION_MESSAGES[ruleName]) as string || '';
};
